import React from 'react';
import Flex from './Flex';

const Col = ({ children, ...props }) => (
  <Flex flexDirection="column" {...props}>
    {children}
  </Flex>
);

export default Col;
