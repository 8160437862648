import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const NavQuery = ({ render }) => (
  <StaticQuery
    query={graphql`
      query SiteNavigationQuery {
        site {
          siteMetadata {
            navigation {
              links {
                title
                subtitle
                type
                url
                content {
                  url
                  sections {
                    title
                    links {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={render}
  />
);

export default NavQuery;
