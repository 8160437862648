import React from 'react';
import styled from 'styled-components';
import NavigationLinks from './NavigationLinks';
import Logo from '../../Logo';
import NavbarWrapper from '../styled/NavbarWrapper';

const Bar = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
  align-self: center;
`;

const CowaitCloudPlaceholder = styled.div`
  width: 80px; // same as temporary Logo/Cowait.jsx
`;

const MainNavigation = () => (
  <NavbarWrapper>
    <Bar>
      <Logo.Cowait />
      <NavigationLinks />
      <CowaitCloudPlaceholder />
    </Bar>
  </NavbarWrapper>
);

export default MainNavigation;
