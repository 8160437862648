import React from 'react';
import styled from 'styled-components';
import DropdownContent, { RichLink } from './DropdownContent';
import Mobile from '../mobile';
import NavQuery from './NavQuery';

const LinkContainer = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.gray.light7};
  ${(p) => p.extraPadding && `padding: 0.75rem`};
`;

const MobileNavigation = () => (
  <NavQuery
    render={(data) => {
      const { links } = data.site.siteMetadata.navigation;

      return (
        <Mobile.BurgerMenu>
          {links.map(({ title, subtitle, type, url, content }) => {
            return (
              <LinkContainer key={`overlay-${title}`} extraPadding={type === 'direct'}>
                {type === 'direct' && <RichLink url={url} title={title} subtitle={subtitle} />}
                {type === 'expand' && (
                  <DropdownContent
                    title={title}
                    subtitle={subtitle}
                    shadow={false}
                    content={content}
                  />
                )}
              </LinkContainer>
            );
          })}
        </Mobile.BurgerMenu>
      );
    }}
  />
);

export default MobileNavigation;
