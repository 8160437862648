import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaGithub } from 'react-icons/fa';

const EditOnGithubLink = styled(Link)`
  color: ${(p) => p.theme.color.gray.default};

  &:visited {
    color: ${(p) => p.theme.color.gray.default};
  }
  &:hover {
    color: ${(p) => p.theme.color.tertiary.default};
  }

  span {
    margin-left: 0.5rem;
  }
`;

const EditOnGithub = ({ title, to }) => (
  <EditOnGithubLink to={to}>
    <FaGithub fontSize="1.25rem" />
    <span>{title}</span>
  </EditOnGithubLink>
);

export default EditOnGithub;
