import React, { useContext, useRef } from 'react';
import { LayoutContext } from '../../Layout';
import StyledBurger from './StyledBurger'
import StyledMenu from './StyledMenu'

const Burger = ({ isSubPage, open, setOpen }) => (
  <StyledBurger isSubPage={isSubPage} open={open} onClick={() => setOpen(!open)}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

const BurgerMenu = ({isSubPage, children}) => {
  const node = useRef();
  const { sideMenuOpen, setSideMenuOpen } = useContext(LayoutContext);
  
  return <div ref={node}>
    <Burger isSubPage={isSubPage} open={sideMenuOpen} setOpen={setSideMenuOpen} />
    <StyledMenu isSubPage={isSubPage} open={sideMenuOpen}>
      {children}
    </StyledMenu>
  </div>
}

export default BurgerMenu;