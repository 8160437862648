import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || 'column'};
  align-items: ${(p) => p.alignItems || 'start'};
  justify-content: ${(p) => p.justifyContent || 'start'};
`;

export default Flex;
