import React from 'react';
import Mobile from '../mobile';
import { DocsNavContent } from './Navbar';

const DocsMobileNav = () => (
  <Mobile.BurgerMenu isSubPage>
    <DocsNavContent />
  </Mobile.BurgerMenu>
);

export default DocsMobileNav;
