import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const DocsQuery = ({ render }) => (
  <StaticQuery
    query={graphql`
      query DocNavigationQuery {
        site {
          siteMetadata {
            navigation {
              docs {
                slug
                title
                children {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={render}
  />
);

export default DocsQuery;
