import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../theme/default';

const Background = styled(({ size, background, circle1, circle2, circle3 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 88 88">
    <g id="Group_1" transform="translate(-1129 -441)">
      <g id="Component_1_1" transform="translate(1129 441)">
        <circle id="main-bg" cx="44" cy="44" r="44" fill={background} />
        <circle id="large-circle" cx="0" cy="8" r="7" transform="translate(8 61)" fill={circle1} />
        <circle id="top-circle" cx="0" cy="5" r="5" transform="translate(22 1)" fill={circle2} />
        <circle id="right-circle" cx="0" cy="5" r="4" transform="translate(81 16)" fill={circle3} />
      </g>
    </g>
  </svg>
))``;

Background.propTypes = {
  size: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  circle1: PropTypes.string.isRequired,
  circle2: PropTypes.string.isRequired,
  circle3: PropTypes.string.isRequired,
};

Background.defaultProps = {
  size: '2em',
  background: theme.color.support.light5,
  circle1: theme.color.primary.default,
  circle2: theme.color.secondary.default,
  circle3: theme.color.tertiary.default,
};

export default Background;
