import React from 'react';
import styled from 'styled-components';
import NavigationLinks from './NavigationLinks';
import Flex from '../../Flex';
import Logo from '../../Logo';
import NavbarWrapper from '../styled/NavbarWrapper';

const VCenter = styled.div`
  align-self: center;
`;

const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: white;
  ${(props) => props.showBorder && `border-bottom: 1px solid ${props.theme.color.gray.light6}`};
`;

const SubPage = () => (
  <VCenter>
    <Flex.Col>
      <Logo.Cowait />
    </Flex.Col>
  </VCenter>
);

const TopBar = ({ isSubPage }) => {
  return (
    <Fixed showBorder={isSubPage}>
      <NavbarWrapper isSubPage={isSubPage}>
        {/* <Link to={url}>
          <Banner> {title} </Banner>
        </Link> */}
        {isSubPage && <SubPage />}
        {isSubPage && <NavigationLinks isSubPage />}
      </NavbarWrapper>
    </Fixed>
  );
};

export default TopBar;
