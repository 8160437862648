import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Popover from 'react-popover';
import { MdExpandMore } from 'react-icons/md';
import DropdownContent from './DropdownContent';
import NavQuery from './NavQuery';

const NavSection = styled.li`
  list-style: none;
  display: flex;
  ${(p) => p.width && `width: ${p.width}`};
  font-size: 0.8rem;
  align-self: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.small}px) {
    display: none;
  }
`;

const NavSectionGroup = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
`;

const NavItem = styled.li`
  display: flex;
  padding: 0 1rem;
`;

export const Links = ({ isSubPage }) => (
  <NavQuery
    render={(data) => {
      const { links } = data.site.siteMetadata.navigation;
      const [popoverOpen, togglePopover] = useState({});

      return (
        <NavSection isSubPage={isSubPage}>
          <NavSectionGroup>
            {links.map(({ title, subtitle, type, url, content }) => {
              return (
                <NavItem key={title}>
                  {type === 'direct' && <Link to={url}>{title}</Link>}
                  {type === 'expand' && (
                    <Popover
                      isOpen={popoverOpen[title]}
                      style={{ zIndex: 5000 }}
                      place={'below'}
                      enterExitTransitionDurationMs={0}
                      onOuterAction={() => togglePopover({ [title]: false })}
                      body={
                        <DropdownContent
                          shadow
                          title={title}
                          subtitle={subtitle}
                          content={content}
                        />
                      }
                    >
                      <div onMouseOver={() => togglePopover({ [title]: true })}>
                        <div>
                          {title} <MdExpandMore />
                        </div>
                      </div>
                    </Popover>
                  )}
                </NavItem>
              );
            })}
          </NavSectionGroup>
        </NavSection>
      );
    }}
  />
);

export default Links;
