import React from 'react';
import styled from 'styled-components';
import { GiBookCover, GiAirBalloon, GiBinoculars, GiBookshelf, GiCubeforce } from 'react-icons/gi';
import { Link } from 'gatsby';
import Flex from '../../Flex';
import Icon from '../../Icon';
import CowaitLink from '../../CowaitLink';

const ContentBackground = styled.div`
  padding: 0.75rem;
  background: white;
  ${(p) => p.shadow && `box-shadow: ${p.theme.shadow.main}`};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 2000;
`;

const iconMap = {
  'Quick Start': GiAirBalloon,
  'Use Cases': GiBinoculars,
  Docs: GiBookCover,
  Features: GiCubeforce,
  Blog: GiBookshelf,
};

const Title = styled.div`
  padding-left: 1rem;
  font-weight: 600;
`;

const Subtitle = styled.div`
  padding-left: 1rem;
  margin-top: 0.1rem;
  max-width: 24rem;
  font-size: 0.85rem;
  color: ${(p) => p.theme.color.gray.default};
`;

const MainLink = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 5px;

  &:hover {
    background: ${(p) => p.theme.color.tertiary.light5};
  }
  &:visited {
    color: unset;
  }
`;

const SectionWrapper = styled(Flex.Row)`
  padding: 1rem;
`;

const Section = styled(Flex.Col)`
  width: 100%;
  text-align: left;
  min-width: 180px;
`;

const SectionTitle = styled.div`
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 4px 0 12px 0;
`;

export const RichLink = ({ url, title, subtitle }) => {
  return (
    <MainLink to={url}>
      <Icon.Enhanced size="2.5em" iconType={iconMap[title]} />
      <Flex.Col>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Flex.Col>
    </MainLink>
  );
};

const DropdownContent = ({ title, subtitle, content, shadow }) => {
  const { url, sections } = content;

  return (
    <ContentBackground shadow={shadow}>
      <RichLink url={url} title={title} subtitle={subtitle} />
      <SectionWrapper>
        {sections.map((section) => (
          <Section key={`section-${section.title}`}>
            <SectionTitle> {section.title}</SectionTitle>
            {section.links.map((link) => (
              <CowaitLink.ChevronRight
                key={`section-link-${link.url}`}
                to={link.url}
                title={link.title}
              />
            ))}
          </Section>
        ))}
      </SectionWrapper>
    </ContentBackground>
  );
};

export default DropdownContent;
