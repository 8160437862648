/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GiBookCover } from 'react-icons/gi';
import CowaitLink from '../../CowaitLink';
import Icon from '../../Icon';
import DocsQuery from './DocsQuery';

const NavContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${(p) => p.theme.page.leftNavMaxWidth};
  background: white;
  height: calc(100vh - ${(props) => props.theme.page.navbarHeight});
  top: ${(props) => props.theme.page.navbarHeight};
  z-index: 999;
  overflow: auto;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.medium}px) {
    display: none;
  }
`;

const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const DocsSectionTitle = styled.div`
  color: ${(p) => (p.light ? p.theme.color.gray.default : p.theme.color.black.light7)};
  font-size: 0.95rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: ${(p) => p.padding || '1.3rem 0 0.35rem 0'};
`;

const DocSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;

  a {
    padding-left: 0.8rem;
  }
`;

const renderList = (list) => {
  return list.map(({ slug, title, children, linksToNewPage, extraMargin }) => {
    if (children && children.length > 0) {
      return (
        <DocSection key={title + slug}>
          <DocsSectionTitle>{title}</DocsSectionTitle>
          {renderList(children)}
        </DocSection>
      );
    } else {
      return (
        <CowaitLink.ChevronRight
          key={slug}
          to={slug}
          title={title}
          padding={linksToNewPage ? '0.5rem 0' : '0.35rem 0'}
          margin={extraMargin ? '2.5rem 0 0 0' : '0'}
        />
      );
    }
  });
};

export const DocsNavContent = () => (
  <DocsQuery
    render={(data) => {
      const { docs } = data.site.siteMetadata.navigation;

      return (
        <NavContent>
          <Icon.Enhanced size="2.5rem" iconType={GiBookCover} />
          <DocsSectionTitle light padding="1rem 0 2.5rem 0">
            Documentation
          </DocsSectionTitle>
          {renderList(docs)}
        </NavContent>
      );
    }}
  />
);

const Navbar = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window !== undefined) {
      ref.current.scrollTop = window.localStorage.getItem('nav-scroll');
    }
  });

  return (
    <NavContainer
      ref={ref}
      onScroll={(event) => {
        window.localStorage.setItem('nav-scroll', event.target.scrollTop);
      }}
    >
      <DocsNavContent />
    </NavContainer>
  );
};

export default Navbar;
