import React from 'react';
import Flex from './Flex';

const Row = ({ children, ...props }) => (
  <Flex flexDirection="row" {...props}>
    {children}
  </Flex>
);

export default Row;
