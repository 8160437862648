import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IconContext } from 'react-icons';
import theme from '../theme/default';
import GlobalStyle from './GlobalStyle';

export const LayoutContext = React.createContext({
  sideMenuOpen: false,
  setSideMenuOpen: () => {},
});

const Layout = ({ location, children }) => {
  // eslint-disable-next-line no-undef
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <LayoutContext.Provider value={{ sideMenuOpen, setSideMenuOpen }}>
        <GlobalStyle sideMenuOpen={sideMenuOpen} />
        <ThemeProvider theme={theme}>
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <div>{children}</div>
          </IconContext.Provider>
        </ThemeProvider>
      </LayoutContext.Provider>
    </div>
  );
};

export default Layout;
