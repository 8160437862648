import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  #___gatsby {
    position: relative;
    width: 100vw;
  }
  
  /* Let's get this party started */
  ::-webkit-scrollbar {
      width: 9px;
      height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      background: rgba(240,240,240,0.7);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.25); 
  }

  body {
    overflow-y: ${(p) => p.sideMenuOpen ? 'hidden' : 'scroll'};
    overflow-x: hidden;
    font-family: Open Sans, -apple-system, BlinkMacSystemFont, Trebuchet MS, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    padding: 0;
    margin: 0;
    min-height: 100%;
    font-size: 1rem;
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-kerning: normal;
    font-style: normal;
    font-weight: 400;
    overflow-wrap: break-word;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    p {
      line-height: 1.5rem;
    }
  }

  p {
    img {
      max-width: calc(100% - 1rem);
    }
  }

  h2 {
    margin: 3.5rem 0 2.2rem 0;
  }

  .Popover-tip {
    fill: white;
  }

  .react-icons {
    vertical-align: middle;
    padding-bottom: 2px;
  }

  .global-wrapper {
    min-height: 100vh;
  }
  
  a, a:visited, a:hover, a:focus, a:active {
    text-decoration: none;
    color: unset;
  }
`;

export default GlobalStyle;
