import styled from 'styled-components';
import Flex from '../../Flex';

const NavbarWrapper = styled(Flex.Row)`
  width: ${(props) => props.theme.page.width};
  max-width: ${(props) => props.theme.page.maxWidth};
  margin: auto;
  justify-content: space-between;
  height: ${(props) => props.theme.page.navbarHeight};
  background: white;
`;

export default NavbarWrapper;
