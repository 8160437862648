import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MdChevronRight } from 'react-icons/md';

const StyledLink = styled(Link)`
  padding: ${(p) => p.padding || '3px 0'};
  font-size: ${(p) => p.fontSize || '0.9rem'};
  margin: ${(p) => p.margin || '0'};

  color: ${(p) => p.theme.color.gray.default};

  &:visited {
    color: ${(p) => p.theme.color.gray.default};
  }
  &:hover {
    color: ${(p) => p.theme.color.tertiary.default};
  }

  &.is-current-link {
    color: ${(p) => p.theme.color.tertiary.default};
  }
`;

const Chevron = styled(MdChevronRight)`
  color: ${(p) => p.theme.color.gray.light4};

  ${StyledLink}:hover & {
    color: ${(p) => p.theme.color.tertiary.default};
  }

  ${StyledLink}.is-current-link & {
    color: ${(p) => p.theme.color.tertiary.default};
  }
`;

const ChevronRight = ({ title, ...props }) => (
  <StyledLink {...props} activeClassName={'is-current-link'}>
    {title}
    <Chevron />
  </StyledLink>
);

export default ChevronRight;
