import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Background from './Background';
import theme from '../../theme/default';

const Relative = styled.div`
  position: relative;
`;

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
`;

const Enhanced = ({ size, iconType, iconColor, ...props }) => (
  <Relative>
    <Background size={size} {...props} />
    <Absolute size={size}>
      {React.createElement(iconType, {
        color: iconColor,
        size: `calc(${size} / 1.6)`,
      })}
    </Absolute>
  </Relative>
);

Enhanced.propTypes = {
  size: PropTypes.string.isRequired,
  iconType: PropTypes.elementType,
  iconColor: PropTypes.string,
  background: PropTypes.string,
  circle1: PropTypes.string,
  circle2: PropTypes.string,
  circle3: PropTypes.string,
};

Enhanced.defaultProps = {
  size: '1em',
  iconColor: theme.color.gray.default,
};

export default Enhanced;
