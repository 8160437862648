import Color from 'color';

const primary = Color('#326CE5');
const secondary = Color('#F05D5E');
const tertiary = Color('#FCB07E');
const support = Color('#D9D0D0');
const gray = Color('#888888');
const black = Color('#111111');

export const Breakpoints = {
  xsmall: 325,
  small: 569,
  medium: 769,
  large: 950,
  xlarge: 1216,
  max: 1300,
};

export default {
  page: {
    navbarHeight: '6rem',
    width: '90%',
    maxWidth: '120rem',
    contentWidth: '42rem',
    leftNavMaxWidth: '16rem',
    contentMaxWidth: '60rem',
    tableOfContentsMaxWidth: '17rem',
  },
  breakpoints: Breakpoints,
  color: {
    primary: {
      default: primary.hex(),
      light1: primary.lighten(0.05).hex(),
      light2: primary.lighten(0.10).hex(),
      light3: primary.lighten(0.15).hex(),
      light4: primary.lighten(0.20).hex(),
      light5: primary.lighten(0.25).hex(),
      light6: primary.lighten(0.30).hex(),
    },
    secondary: {
      default: secondary.hex(),
      light1: secondary.lighten(0.05).hex(),
      light2: secondary.lighten(0.10).hex(),
      light3: secondary.lighten(0.15).hex(),
      light4: secondary.lighten(0.20).hex(),
      light5: secondary.lighten(0.25).hex(),
      light6: secondary.lighten(0.30).hex(),
    },
    tertiary: {
      default: tertiary.hex(),
      light1: tertiary.lighten(0.05).hex(),
      light2: tertiary.lighten(0.10).hex(),
      light3: tertiary.lighten(0.15).hex(),
      light4: tertiary.lighten(0.20).hex(),
      light5: tertiary.lighten(0.25).hex(),
      light6: tertiary.lighten(0.30).hex(),
    },
    support: {
      default: support.hex(),
      light1: support.lighten(0.03).hex(),
      light2: support.lighten(0.06).hex(),
      light3: support.lighten(0.09).hex(),
      light4: support.lighten(0.12).hex(),
      light5: support.lighten(0.15).hex(),
      light6: support.lighten(0.18).hex(),
    },
    gray: {
      default: gray.hex(),
      light1: gray.lighten(0.15).hex(),
      light2: gray.lighten(0.30).hex(),
      light3: gray.lighten(0.45).hex(),
      light4: gray.lighten(0.55).hex(),
      light5: gray.lighten(0.63).hex(),
      light6: gray.lighten(0.71).hex(),
      light7: gray.lighten(0.79).hex(),
      light8: gray.lighten(0.82).hex(),
    },
    black: {
      default: black.hex(),
      light1: black.lighten(0.50).hex(),
      light2: black.lighten(0.75).hex(),
      light3: black.lighten(1.00).hex(),
      light4: black.lighten(1.50).hex(),
      light5: black.lighten(2.00).hex(),
      light6: black.lighten(2.50).hex(),
      light7: black.lighten(3.00).hex(),
    }
  },
  gradient: {
    secondaryTertiary: {
      main: `linear-gradient(90deg,${secondary.hex()} 0%,${tertiary.hex()} 100%)`,
      dark: `linear-gradient(90deg,${secondary.darken(0.05).hex()} 0%,${tertiary.darken(0.05).hex()} 100%)`,
    }
  },
  shadow: {
    main: "2px 2px 10px 1px rgb(0 0 0 / 30%)"
  },
};
