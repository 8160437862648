import styled from 'styled-components';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: white;
  transform: ${({ open, isSubPage }) => (open ? `translateX(${isSubPage ? '40%' : '0'})` : `translateX(100%)`)};
  height: calc(100vh - 7rem);
  text-align: left;
  position: fixed;
  top: ${p => p.theme.page.navbarHeight};
  right: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 1000;
  border-top: 1px solid ${(p) => p.theme.color.gray.light6};
  border-left: 1px solid ${(p) => p.theme.color.gray.light6};
  overflow: auto;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.xsmall}px) {
    transform: ${({ open }) => (open ? 'translateX(0)' : `translateX(100%)`)};
  }
`;

export default StyledMenu;
