import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// this is not intended to stay
// change logo to svg, use the link on a parent element

const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 80px;
`;

const CowaitLogo = () => <StyledLink to={'/'}>Cowait</StyledLink>;

export default CowaitLogo;
